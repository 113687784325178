import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Jobs } from '../../pages/users/list-jobs/jobs.model';
import { tap, catchError } from 'rxjs/operators';
import { AuthGuardService } from '../guards/auth-guard.service';
import { StorageService } from '../guards/storage.service';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  private token = this.storageService.getToken();

  httpOptions = {
    // headers: new HttpHeaders({
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With',
      'Authorization': 'Bearer ' + this.token,
    }
  };

  constructor(private http: HttpClient, 
    private service: AuthGuardService,
    private storageService: StorageService) { }

  getUsersJobs(id): Observable<any> {
    const url = this.service.apiUrl + `/get-user-jobs?_user=${id}`;
    return this.http.get(url, this.httpOptions);
  }

  getUserRolesCompany(user, company): Observable<any> {
    const url = this.service.apiUrl + `/get-user-roles-company?_user=${user}&_company=${company}`;
    return this.http.get(url, this.httpOptions);
  }

  removeUsersJobs(data): Observable<any> {
    const url = this.service.apiUrl + `/remove-job`;
    return this.http.post(url, data, this.httpOptions);
  }

  getAllRoles(): Observable<any> {
    const url = this.service.apiUrl + `/get-all-roles`;
    return this.http.get(url, this.httpOptions);
  }

  asingUsersJobs(data): Observable<any> {
    const url = this.service.apiUrl + `/asign-job`;
    return this.http.post(url, data, this.httpOptions);
  }
}
