import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Company } from '../../pages/companies/company.model';
import { tap, catchError } from 'rxjs/operators';
import { AuthGuardService } from '../guards/auth-guard.service';
import { StorageService } from '../guards/storage.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  private token = this.storageService.getToken();

  httpOptions = {
    // headers: new HttpHeaders({
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With',
      'Authorization': 'Bearer ' + this.token,
    }
  };

  constructor(private http: HttpClient, 
    private service: AuthGuardService,
    private storageService: StorageService) { }

  getAllCompanies(): Observable<any> {
    const url = this.service.apiUrl + '/get-all-companies';

    return this.http.get(url, this.httpOptions);


  }

  addCompanies (companies): Observable<Company> {
    const url = this.service.apiUrl + '/register-company';
    return this.http.post<Company>(url, companies, this.httpOptions);
  }

  updateCompanies (companies): Observable<Company> {
    const url = this.service.apiUrl + '/update-company';
    return this.http.post<Company>(url, companies, this.httpOptions);
  }

  getCompany (id): Observable<Company> {
    const url = this.service.apiUrl + `/get-company?_id=${id}`;
    return this.http.get<Company>(url, this.httpOptions);
  }

  getUserCompanies (id): Observable<any> {
    const url = this.service.apiUrl + `/get-user-companies?_user=${id}`;
    return this.http.get(url, this.httpOptions);
  }
}


